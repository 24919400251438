<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer
      :expand-on-hover="expandOnHover"
    />

    <alerts />

    <dashboard-core-view />
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',
    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreView: () => import('./components/core/View'),
      Alerts: () => import('../../components/Alerts'),
    },
    data: () => ({
      expandOnHover: false,
    }),
  }
</script>

<style scoped>
  .alerts {
    position: fixed;
    z-index: 999999;
    right: 20px;
    min-width: 40%;
    bottom: 30px;
  }
</style>
